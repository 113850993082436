import React, { useState, useEffect,Fragment } from "react";
import axios from "../axios-interceptor";
import { useHistory, useLocation } from "react-router-dom";
import { base_url,date_formats, Program_type,random_number, razorpay_key,api_error_message, land_nave_storefront_url, standalone_program_type, user_maintenance_type } from "../utilities";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { OverlayTrigger,Tooltip } from 'react-bootstrap';
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import ReactSimpleTooltip from "react-simple-tooltip";
import withScreenSizeCheck from "../HigherOrderComponents/withScreenSizeCheck";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
import { Modal } from "react-bootstrap";
function ProgressBar(props) {
  const { completed } = props;
  const containerStyles = {
    height: 5,
    width: "100%",
    backgroundColor: "#D9D9D9",
    marginTop: 40,
    marginRight:14
  };

  const fillerStyles = {
    width: `${completed}%`,
    textAlign: "end",
    marginTop: -12,
    marginLeft: completed==100?4:-2,
  };

  const labelStyles = {
    padding: 5,
    zIndex: -1,
    color: "transparent",
  };
  return (
    <div data-testid="dashboard_CourseProgress" className="dashboard_CourseProgress">
      <div style={fillerStyles}>
        <img
          className="dashboard_CourseProgressimg"
          src="./images/diamond.svg"
          alt=""
        />
      </div>
    </div>
  );
}
const DashBoard = () => {
  let history = useHistory();
  let [programs, setPrograms] = useState([]);
  let [selectedPrograme,setSelectedPrograme]=useState({})
  let [loading_flag, setLoading_flag] = useState(true);
  let [noData_flag, setNoData_flag] = useState(false);
  let [user_name, setUserName] = useState("");
  let [program_msg, setProgramMsg] = useState("");
  let [heatmap_data, setHeatmapData] = useState([]);
  let [dynamic_badge, SetDynamicBadge] = useState({});
  let [programsList, setProgramsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  let [program_options, setprogramOptions] = useState([]);
  let [verizon_student,setVerizonStudent]= useState(1)
  let [showModal,setShowModal]= useState(false)
  let [page_size,setPageSize] = useState(0)
  let [show_more,setShowMore] = useState(true);
  let [show_loader,setShowLoader] = useState(false)
  let [api_response,setApiResponse] = useState({});
  let [show_more_disable,Setshow_more_disable] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,control,
    formState: { errors },
  } = useForm({ mode: "all"});
  // if (!localStorage.getItem("login")) {
  //   history.push("/");
  // }


  useEffect(() => {
    if(!localStorage.getItem("user_id"))
    history.push('/')
 
    axios
      .post(base_url + "user/get_activity_heat_map_data", {
        user_id: localStorage.getItem("user_id"),
      })
      .then((res) => {
        let activity_data = [...res.data.data].filter((item) => {
          item.date =  date_formats.date_format(item.date);
          item.count = item.time_spent;
          if(item.count == 0 ){
            item.time = 0
            item.hours = 0
            item.minutes = 0
            item.seconds = 0
          }else{
           let d = item.time.split(':');
            item.hours = Number(d[0])
            item.minutes = Number(d[1])
            item.seconds = Number(d[2])
          }
          delete item.time_spent;
        });
        setHeatmapData(res.data.data);
      }).catch((err)=>{
        setLoading_flag(false);
          localStorage.clear();
          history.push('/')
          window.location.reload();
      
      });
  
  }, []);

  useEffect(()=>{
    getProgrammes(page_size)
  },[page_size])
  const getProgrammes = (page) =>{
    Setshow_more_disable(true)
     let params ={user_id: localStorage.getItem("user_id"),
    page: page}
      axios
      .get(base_url + "user/my_programs", {params})
      .then((res) => {
        Setshow_more_disable(false)
        if (res.data.data == "No Data Found for the user" && page==0) {
          setLoading_flag(false);
          setNoData_flag(true);
          setShowMore(false)
        }else if(res.data.data == "No Data Found for the user"){
          setLoading_flag(false);
          setShowMore(false)
        } else if(Object.keys(res.data.data).length != 0){
          setLoading_flag(false);
          if(res.data?.data?.is_verizon_student == 1)
          setShowModal(true)
            //check profile_filled
            localStorage.setItem("profile_filled",res.data?.data?.profile_filled)
            if(res.data?.data?.profile_filled==0 &&(localStorage.getItem('is_profile_mandatory') == 'undefined' || localStorage.getItem("is_profile_mandatory") !== 'false'))
            {
                localStorage.setItem("profile_filled",0)
                history.push('/userprofile',{key:"profile"})
           }
            else{
              let programs = [res.data.data.program_info];
              setUserName(()=>res.data.data.first_name);
              setProgramMsg(res.data.data.program_message)
              programs.map((data) => {
                data.courses.map((item, index) => {
                  if (index == 0) {
                    item["selected_item"] = true;
                    item["background_color"] = "#057092";
                    item["color"] = "white";
                  } else {
                    item["selected_item"] = false;
                    item["color"] = "#057092";
                    item["background_color"] = "white";
                  }
                });
              });
              programs.map((data) => {
                data.courses.map((course) => {
                  course["module_release_date"] = 0;
                  for (var module of course.modules) {
                    if (module.start_date > date_formats.convert_current_date_utc_format()) {
                      course["module_release_date"] = module.start_date;
                      break;
                    }
                  }
                });
              });
              // if(page%2 == 0)
              // }else{
              //   setApiResponse(res.data.data)
              // }
             

             
              // setPrograms(programs);
              // setProgramsList(programs)
              setprogramOptions(
                programs.map(({ program_id, learning_track_title }) => ({ value: program_id, label: learning_track_title }))
                );
                if(programs.length>0)
                {
                  const {program_id,learning_track_title}=programs[0]
                  setSelectedOption({ value: program_id, label: learning_track_title })
                }
              setSelectedPrograme(programs[0])
           
              if(page == 0){
                getNextProgrammes(page=1)
                programs.map(list_item =>{
                  setPrograms((prev)=>[...prev,list_item])
                  setProgramsList((prev)=>[...prev,list_item])
                })
              }else{
                setShowMore(true);
                setShowLoader(false);
                setApiResponse(res.data.data)
              }
              
              
             
            }
        }else{
          setLoading_flag(false);
          setShowMore(false)
          setShowLoader(false)
        }
       
      })
      .catch((err) => {
        setLoading_flag(false);
        setShowLoader(false)
          localStorage.clear();
          history.push('/')
          window.location.reload();
        
      });
  }
  const getNextProgrammes = (page)=>{
    let params ={user_id: localStorage.getItem("user_id"),
    page: page}
      axios
      .get(base_url + "user/my_programs", {params})
      .then((res) => {
        if(Object.keys(res.data.data).length != 0){
          setApiResponse(res.data.data)
          setShowMore(true)
          setShowLoader(false);
        }else{
      setShowMore(false)

        }
        // setShowLoader(false)
        // setOldProgrammes(programmes)
      })
      .catch((err) => {
        setShowLoader(false)
        setLoading_flag(false);
          localStorage.clear();
          history.push('/')
          window.location.reload();
      });
  }
  const showMore = () =>{
    // setShowLoader(true)
    if (Object.keys(api_response).length == 0 && page_size==1) {
      // let programs= [old_programmes]
      programs.map((data) => {
        data.courses.map((item, index) => {
          if (index == 0) {
            item["selected_item"] = true;
            item["background_color"] = "#057092";
            item["color"] = "white";
          } else {
            item["selected_item"] = false;
            item["color"] = "#057092";
            item["background_color"] = "white";
          }
        });
      });
      programs.map((data) => {
        data.courses.map((course) => {
          course["module_release_date"] = 0;
          for (var module of course.modules) {
            if (module.start_date > date_formats.convert_current_date_utc_format()) {
              course["module_release_date"] = module.start_date;
              break;
            }
          }
        });
      });
      
      setPrograms(programs);
      setProgramsList(programs)
      setprogramOptions(
        programs.map(({ program_id, learning_track_title }) => ({ value: program_id, label: learning_track_title }))
        );
        if(programs.length>0)
        {
          const {program_id,learning_track_title}=programs[0]
          setSelectedOption({ value: program_id, label: learning_track_title })
        }
      setSelectedPrograme(programs[0])
      setLoading_flag(false);
      setShowMore(false)
    }else if(Object.keys(api_response).length != 0){
      if(api_response?.is_verizon_student == 1)
      setShowModal(true)
        //check profile_filled
        localStorage.setItem("profile_filled",api_response?.profile_filled)
        if(api_response?.profile_filled==0 && (localStorage.getItem('is_profile_mandatory') == 'undefined' || localStorage.getItem("is_profile_mandatory") !== 'false'))
        {
            localStorage.setItem("profile_filled",0)
            history.push('/userprofile',{key:"profile"})
         }
        else{
          
          let programs_list = [api_response.program_info];
          setUserName(()=>api_response.first_name);
          setProgramMsg(api_response.program_message)
          programs_list.map((data) => {
            data.courses.map((item, index) => {
              if (index == 0) {
                item["selected_item"] = true;
                item["background_color"] = "#057092";
                item["color"] = "white";
              } else {
                item["selected_item"] = false;
                item["color"] = "#057092";
                item["background_color"] = "white";
              }
            });
          });
          programs_list.map((data) => {
            data.courses.map((course) => {
              course["module_release_date"] = 0;
              for (var module of course.modules) {
                if (module.start_date > date_formats.convert_current_date_utc_format()) {
                  course["module_release_date"] = module.start_date;
                  break;
                }
              }
            });
          });
          // setPrograms(programs);
          programs_list.map(list_item =>{
            setPrograms((prev)=>[...prev,list_item])
            setProgramsList((prev)=>[...prev,list_item])
          })
         
          setprogramOptions(
            programs_list.map(({ program_id, learning_track_title }) => ({ value: program_id, label: learning_track_title }))
            );
            if(programs_list.length>0)
            {
              const {program_id,learning_track_title}=programs_list[0]
              setSelectedOption({ value: program_id, label: learning_track_title })
            }
          setSelectedPrograme(programs_list[0])
          setLoading_flag(false);
        }
    }
    else if(Object.keys(api_response).length == 0){
      // let programmes_list = [old_programmes]
      // let programs= [old_programmes]
      programs.map((data) => {
        data.courses.map((item, index) => {
          if (index == 0) {
            item["selected_item"] = true;
            item["background_color"] = "#057092";
            item["color"] = "white";
          } else {
            item["selected_item"] = false;
            item["color"] = "#057092";
            item["background_color"] = "white";
          }
        });
      });
      programs.map((data) => {
        data.courses.map((course) => {
          course["module_release_date"] = 0;
          for (var module of course.modules) {
            if (module.start_date > date_formats.convert_current_date_utc_format()) {
              course["module_release_date"] = module.start_date;
              break;
            }
          }
        });
      });
      programs.map(list_item =>{
        setPrograms((prev)=>[...prev,list_item])
        setProgramsList((prev)=>[...prev,list_item])
      })
      setprogramOptions(
        programs.map(({ program_id, learning_track_title }) => ({ value: program_id, label: learning_track_title }))
        );
        if(programs.length>0)
        {
          const {program_id,learning_track_title}=programs[0]
          setSelectedOption({ value: program_id, label: learning_track_title })
        }
      setSelectedPrograme(programs[0])
      setLoading_flag(false);
      setShowMore(false)
     
    }
    else{
      setLoading_flag(false);
      setShowMore(false)
      setShowLoader(false)
    }
    // if(page_size >2)
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 350)
    if(page_size == 0){
      setPageSize(2)
    }else{
      setPageSize(page_size+1)
    }
  }
  const ShowDynamicBadge =  (id) => { 
    SetDynamicBadge({})
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: true }));
  }

  const HideDynamicBadge =  (id) => {
     SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: false }));
  }


  //payment implementation
  const handlePayment =  (program_id) => {
    localStorage.setItem('setPassword_UserId',localStorage.getItem('user_id'))
    localStorage.setItem('setPassword_programId',program_id)
    history.push("/addressapplicationform");
  };

  const setCourse = (program_index, index) => {
    let program = [...programs];
    program.map((data, index1) => {
      if (index1 == program_index) {
        data.courses.map((item, index2) => {
          if (index2 == index) {
            item["selected_item"] = true;
            item["background_color"] = "#057092";
            item["color"] = "white";
          } else {
            item["selected_item"] = false;
            item["color"] = "#057092";
            item["background_color"] = "white";
          }
        });
      }
    });
    setPrograms(program);
  };
  const setCourseInMobileView=(index)=>{
    let selectedprogram = JSON.parse(JSON.stringify(selectedPrograme))
    selectedprogram.courses.map((item, index2) => {
          if (index2 == index) {
            item["selected_item"] = true;
            item["background_color"] = "#057092";
            item["color"] = "white";
          } else {
            item["selected_item"] = false;
            item["color"] = "#057092";
            item["background_color"] = "white";
          }
        });
    
    setSelectedPrograme(selectedprogram)
  }
  const redirectToModulePage = (id, cohort_id) => {
    localStorage.setItem("cohort_id", cohort_id);
    localStorage.setItem("user_id", localStorage.getItem("user_id"));
    localStorage.setItem("course_id", id);
    history.push("/modulepage");
  };

  const handleSelectChange=(val)=>{
    let filterItem=programsList.filter(programe=>programe.program_id==val.value)
    if(filterItem.length>0)
      setSelectedPrograme(filterItem[0])
      setSelectedOption(val)
  }
const goToStoreFront = (link) =>{
  window.open(link?link:land_nave_storefront_url+'learning-tracks', '_blank')
}
const handleShowModal = () =>{
  setVerizonStudent(2)
  axios
  .post(base_url + "isb/user/update_verizon_student_details", {
    id: localStorage.getItem("user_id"),
    first_name: user_name,
    is_verizon_student:2
  }).then(res=>{
  })
  .catch((err) => {
    if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
      localStorage.clear();
      history.push('/')
      window.location.reload();
    }else{
      toast.warn(api_error_message,{toastId:random_number});
    }
  });
  setShowModal(false)
}
const handleChange = (e) =>{
  setUserName(e.target.value)
}

  return (
    <>
    
      {loading_flag ? (
        // <div className="spinner-border custom-spinner" role="status">
        //   <span className="visually-hidden">Loading...</span>
        // </div>
        <div>
        <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
      </div>
      ) : program_msg ? 
        <>
          <div className="container content_div justify-content-center dashboard_nodata">
            <h3 className="d-block text-center">
              {program_msg}
            </h3>
          </div>
        </> : noData_flag ? (
        <>
          <div className="container content_div justify-content-center dashboard_nodata">
            <h3 className="d-block text-center">
              No programms are available right now please buy a Programme and
              come to the dashboard...!
            </h3>
          </div>
        </>
      ) : (
        <>
        <div className="dashboardResponsivePadding">
          <span data-testid="welcomeText" className="welcome_text container mt-lg-5">
            Welcome, {user_name.charAt(0).toUpperCase() + user_name.slice(1)}!
          </span>
          <div className="Dactivity_container my_activity container mt-4">
            <div className="d-flex justify-content-between">
              <div className="">My Activity</div>
              <div className="">
                <OverlayTrigger  placement="top" overlay={<Tooltip className="dashboard_tooltip">This graph shows your daily<br></br>activity on ISB online</Tooltip>}
                >
                  <img src="images/dashboard_questionmark.svg"></img>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          <div className="Dactivity_container container row dashboard_shadow bg-body text-left mt-2 p-2">
            <div data-testid="heatMap" className={`pt-3  ${heatmap_data.length != 0  ? '' : 'opacity-25'}`}>
              <CalendarHeatmap
                startDate={date_formats.date_format_heatmap_start_date()}
                endDate={date_formats.date_format_heatmap_end_date()}
                values={heatmap_data}
                gutterSize={5}
                showWeekdayLabels={true}
                weekdayLabels={["S", "M", "T", "W", "T", "F", "S"]}
                monthLabels={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                classForValue={(value) => {
                  if (!value) {
                    return "color-empty";
                  } else if (value.count == 0) {
                    return "color-start";
                  } else if (value.count > 0 && value.count < 1) {
                    return `color-scale-0`;
                  } else if (value.count >= 1 && value.count < 2) {
                    return `color-scale-1`;
                  } else if (value.count >= 2 && value.count < 4) {
                    return `color-scale-2`;
                  } else if (value.count >= 4) {
                    return `color-scale-3`;
                  } else {
                    return "color-empty";
                  }
                }}
                tooltipDataAttrs={(value) => {
                  if (!value.time) {
                    // If 'time' is not present, show 'No Activity'
                    return {
                      "data-tip": '<div class="top-tip">No Activity</div>',
                    };
                  } else {
                    // If 'time' is present, check for hours and minutes
                    let tooltipContent = '';
                    if (value.hours !== 0 || value.minutes !== 0) {
                      // Show tooltip for hours and minutes if they are non-zero
                      if (value.hours !== 0) {
                        tooltipContent += `${value.hours} hrs `;
                      }
                      if (value.minutes !== 0) {
                        tooltipContent += `${value.minutes} mins`;
                      }
                    } else {
                      // If both hours and minutes are zero, show only minutes
                      tooltipContent = '1 min';
                    }
                
                    return {
                      "data-tip": `<div class="top-tip">${tooltipContent}</div><div class="bottom-tip">${date_formats.human_week_date_format(value.date)}</div>`,
                    };
                  }
                }}
                
                
              />
              <ReactTooltip
                className="dashboard-tooltip"
                type="info"
                effect="solid"
                html={true}
              />
              <div className="heatmap_bottom">
                <div className="float-start ">
                  Daily activity based on time spent{" "}
                </div>
                <div className="d-flex float-end justify-content-between">
                  <div>Less</div>
                  <ReactSimpleTooltip
                    id="heatmap-tooltip"
                    content="&nbsp;&nbsp;&nbsp;0"
                    arrow={10}
                    radius={13}
                    zIndex={1}
                    background="#fff"
                    color="#000"
                    border="#057092"
                    placement="top"
                  >
                    <div
                      className="heatmap_div"
                      style={{ backgroundColor: "#ECECEC" }}
                    ></div>
                  </ReactSimpleTooltip>
                  <ReactSimpleTooltip
                    id="heatmap-tooltip0"
                    content="0-1 Hour"
                    arrow={10}
                    radius={13}
                    zIndex={1}
                    background="#fff"
                    color="#000"
                    border="#057092"
                    placement="top"
                  >
                    <div
                      className="heatmap_div"
                      style={{ backgroundColor: "#B7C6D4" }}
                    ></div>
                  </ReactSimpleTooltip>
                  <ReactSimpleTooltip
                    id="heatmap-tooltip1"
                    content="1-2 Hours"
                    arrow={10}
                    radius={13}
                    zIndex={1}
                    background="#fff"
                    color="#000"
                    border="#057092"
                    placement="top"
                  >
                    <div
                      className="heatmap_div"
                      style={{ backgroundColor: "#708FAB" }}
                    ></div>
                  </ReactSimpleTooltip>
                  <ReactSimpleTooltip
                    id="heatmap-tooltip2"
                    content="2-4 Hours"
                    arrow={10}
                    radius={13}
                    zIndex={1}
                    background="#fff"
                    color="#000"
                    border="#057092"
                    placement="top"
                  >
                    <div
                      className="heatmap_div"
                      style={{ backgroundColor: "#378DA8" }}
                    ></div>
                  </ReactSimpleTooltip>
                  <ReactSimpleTooltip
                    id="heatmap-tooltip3"
                    content="4+ Hours"
                    arrow={10}
                    radius={13}
                    zIndex={1}
                    background="#fff"
                    color="#000"
                    border="#057092"
                    placement="top"
                  >
                    <div
                      className="heatmap_div"
                      style={{ backgroundColor: "#057092" }}
                    ></div>
                  </ReactSimpleTooltip>
                  <div>More</div>
                </div>
              </div>
            </div>
          </div>
          {window.innerWidth < 993 ?
          <>
          <div className="container row dashboard_shadow bg-body text-left  mt-lg-5 mobi">
            <span className="my_program pt-4 pl-4">My Programmes</span>


            {/* {programs.map((item1, index) => {
              return (
                <Fragment key={index}> */}
                  <div
                    // key={index}
                    className="px-4 pb-4"
                    style={{ backgroundColor: "#FCFAFA" }}
                  >
                    <div className="mb-3">
                        {/* <span className="dashboard_subHead">{item1.learning_track_title}</span> */}
                        <div className="" data-testid="my_progammes_drpdwn">
                          {/* <select
                          autosize={true}
                            name="programme"
                            id="programme"
                            className="form-select rounded dashboard_program_dropdown"
                            role="presentation" autocomplete="off"
                            aria-label="Default select example"
                            onChange={handleProgramChange}
                          >
                            <option value="" disabled>Select Programe</option>
                            {programsList.map(item=>{
                              return(
                                <option key={item.program_id} value={item.program_id}>{item.learning_track_title}</option>
                              )
                            })}
                          </select>
                          <br />
                          <br /> */}
                            <span class="d-none" data-testid="handleSelectCall" onClick={()=>handleSelectChange({ value: "65647bd7af3e2e1460920b5d", label: "Leadership Essentials SD" })}></span>
                           <Controller
                              as={Select}
                              name="users"
                              placeholder="Course Name"
                              options={program_options}
                              className="Dashboard_progdrpdwn rounded"
                              control={control}
                              rules={{ required: true }}
                              render={({ onChange, value, name }) => (
                              <Select
                                  onChange={handleSelectChange}
                                  value={selectedOption}
                                  name={name}
                                  options={program_options}
                                  isSearchable={false}
                                  classNamePrefix="custom-select-dashbrd"
                                  />
                                  )}
                              />
                        </div>
                      {selectedPrograme?.application_status == 2 ? (
                        selectedPrograme?.courses[0].is_paid == 1 ? (
                          selectedPrograme?.program_start_date > date_formats.convert_current_date_utc_format() && (
                            <></>
                            // <button
                            //   onClick={showReminder}
                            //   className="btn dashboard_button mt-0"
                            // >
                            //   Get Reminder
                            // </button>
                          )
                        ) : (
                          <button
                            onClick={() => handlePayment(selectedPrograme?.program_id)}
                            className="btn dashboard_button mt-0 mt-3" data-testid="make_payment_mobile"
                          >
                            Make Payment
                          </button>
                        )
                      ) : (
                        <p className="mt-2">
                          <b>Pending For Approval</b>
                        </p>
                      )}
                    </div>
                    <div className="row ml-lg-1">
                   
                      <div className="col-lg-4 pr-lg-5 coursenamePadding">
                        {Object.keys(selectedPrograme).length>0&&selectedPrograme?.courses.map((course, ind) => {
                          return (
                            <Fragment key={ind}>
                               {(selectedPrograme && selectedPrograme.program_type == standalone_program_type) && selectedPrograme.courses.length == 1 ?
                      <div className="col-lg-4 p-0 pr-lg-5 mb-2">
                        <div className="container dashboard_shadow standalone-course">
                        <p > {selectedPrograme.learning_track_message?selectedPrograme.learning_track_message:`This course is part of the ${selectedPrograme.learning_track_title} Learning Track`}</p>
                        <button className="learn_more" data-testid="learn_more_mobile" onClick={()=>goToStoreFront(selectedPrograme.learning_track_url)}>Learn More<img src="images/learn_more.svg"></img></button>
                          </div>
                        </div>: 
                              <div className="container mb-2">
                                <div
                                  className="row p-2 dashboard_shadow"
                                  style={{
                                    backgroundColor: course.background_color,
                                  }}
                                  data-testid={`courseView_mobile_${ind}`}
                                  onClick={() => setCourseInMobileView(ind)}
                                >
                                  <div className="col-lg-1 col-sm-1 col-1 pl-1 pt-2">
                                    {course.cohort_start_date >
                                    date_formats.convert_current_date_utc_format() ? (
                                      <>
                                        {course.selected_item ? (
                                          <img className="dashboard-image-vertical-align" src="images/lock_selected.svg"></img>
                                        ) : (
                                          <img  className="dashboard-image-vertical-align" src="images/lock.svg"></img>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {course.modules_count ==
                                        course.completed_modules_count && course.course_completed_date ? (
                                          course.selected_item ? (
                                            <img className="dashboard-image-vertical-align"  src="images/completed_selected.svg"></img>
                                          ) : (
                                            <img className="dashboard-image-vertical-align"  src="images/completed.svg"></img>
                                          )
                                        ) : course.completed_modules_count >=
                                          0 ? (
                                          course.selected_item ? (
                                            <img  className="dashboard-image-vertical-align" src="images/resume_selected.svg"></img>
                                          ) : (
                                            <img className="dashboard-image-vertical-align"  src="images/resume.svg"></img>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-11 col-sm-11 col-11 pr-1 cursor-pointer">
                                    <span
                                      className="program_span"
                                      style={{ color: course.color }}
                                    >
                                      {course.course_title}
                                    </span>
                                    <br />
                                    <span className="program_span_status" style={{ color: course.color }}>
                                      {course.cohort_start_date <
                                      date_formats.convert_current_date_utc_format() ? (
                                        <>
                                          {course.modules_count ==
                                          course.completed_modules_count && course.course_completed_date 
                                            ? `Completed on ${date_formats.human_date_format(
                                                course.course_completed_date
                                              )} `
                                            : `${date_formats.convert_current_date_utc_format()>course.cohort_start_date?'Started':'Starts'} on ${date_formats.human_date_format(
                                                course.cohort_start_date
                                              )}`}
                                        </>
                                      ) : (
                                       
                                         `Starts on ${date_formats.human_date_format(
                                          course.cohort_start_date
                                        )}`
                                     )}
                                    </span>
                                  </div>
                                </div>
                              </div>}
                              {/* course container */}
                              {course.selected_item&&<>
                                <div className="row">
                              <div className="col-12 p-0">
                                {Object.keys(selectedPrograme).length>0&&selectedPrograme?.courses.map((item, idx) => {
                                   
                                  return item.selected_item ? (
                                    <Fragment key={idx+"innerItems1"}>
                                      <div className="container dashboard_shadow">
                                        <div className="container">
                                          <img
                                            className="img-fluid"
                                            src={
                                              item.image === "" ||
                                              item.image === "null" ||
                                              item.image == null
                                                ? "images/course1.svg"
                                                : item.image
                                            }
                                            alt=""
                                          ></img>
                                        </div>

                                        <div className="p-4">
                                        {item.show_overview && <> <div className="d-flex justify-content-end">
                                            <>
                                              <ProgressBar
                                                // key={idx}
                                                completed={
                                                  (100 / item.modules_count) *
                                                  item.completed_modules_count
                                                }
                                              />
                                              <img
                                                className="dashboard_coursecertificate course_certificate_img"
                                                src="./images/Course_Certificate_dashboard.png"
                                                alt=""
                                              />
                                            </>
                                          </div>
                                          <div
                                            className="row dashboard_badgesContainer" data-testid="module_badges_mobile">
                                            {item.modules.map((badges,idnx) => {
                                              return badges.is_module_completed ==
                                                false && badges.module_badge == 0 ? (
                                                <Fragment key={idnx+"innnerLoop2"+idx}>
                                                  {dynamic_badge["registerTip0" + badges._id +badges.start_date] ? <OverlayTrigger placement={idnx==0?'top-start':'top'} overlay={
                                                    <Tooltip className="dashboard_tooltip"
                                                    id={"registerTip0" + badges._id +badges.start_date}>
                                                      <span>
                                                      {badges?.module_title}
                                                      </span>
                                                      <br />
                                                      <span>
                                                        Finish before {date_formats.human_date_format(badges.end_date)}
                                                      </span>
                                                      <span></span>
                                                    </Tooltip>} offset={[0,20]}>
                                                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + badges._id +badges.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge_mobile" id={"registerTip0" + badges._id +badges.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + badges._id +badges.start_date)} >
                                                      <div className="isb_badge_text">
                                                        <p className="text-center mb-2 white_color">{badges?.module_title?.length >=43 ?  badges?.module_title.slice(0, 43)+ '...' : badges?.module_title }</p>
                                                        {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                                      </div>
                                                    </div>
                                                  </OverlayTrigger> :  
                                                  <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + badges._id +badges.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge_mobile"  id={"registerTip0" + badges._id +badges.start_date}>
                                                    <div className="isb_badge_text">
                                                      <p className="text-center mb-2 white_color">{badges?.module_title}</p>
                                                      {/* <p className="text-center white_color">{item.course_title}</p> */}
                                                    </div>
                                                  </div>
                                                  }
                                                </Fragment>
                                                ) : badges.is_module_completed == true &&
                                                badges.module_badge == 0 ? (
                                                  < Fragment key={idnx+"innnerLoop2"+idx}>
                                                  {dynamic_badge["registerTip1" + badges._id +badges.start_date] ? <OverlayTrigger placement={idnx==0?'top-start':'top'} overlay={
                                                    <Tooltip className="dashboard_tooltip"
                                                    id={"registerTip1" + badges._id +badges.start_date}>
                                                      <span>
                                                        {badges?.module_title}
                                                      </span>
                                                      <br />
                                                      <span>
                                                        You Missed This Badge
                                                      </span>
                                                      <span></span>
                                                    </Tooltip>} offset={[0,20]}>
                                                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip1" + badges._id +badges.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge_mobile"  id={"registerTip1" + badges._id +badges.start_date} onMouseLeave={() => HideDynamicBadge("registerTip1" + badges._id +badges.start_date)} >
                                                      <div className="isb_badge_text">
                                                        <p className="text-center mb-2 white_color">{badges?.module_title?.length >= 43 ?  badges?.module_title.slice(0, 43)+ '...' : badges?.module_title }</p>
                                                        {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                                      </div>
                                                    </div>
                                                  </OverlayTrigger> :  
                                                  <div  onMouseEnter={() => ShowDynamicBadge("registerTip1" + badges._id +badges.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge_mobile"  id={"registerTip1" + badges._id +badges.start_date}>
                                                    <div className="isb_badge_text">
                                                      <p className="text-center mb-2 white_color">{badges?.module_title}</p>
                                                      {/* <p className="text-center white_color">{item.course_title}</p> */}
                                                    </div>
                                                  </div>
                                                  }
                                                </Fragment>
                                                ) : (
                                                  <Fragment key={idnx+"innnerLoop2"+idx}>
                                                  {dynamic_badge["registerTip2" + badges._id +badges.start_date] ? <OverlayTrigger placement={idnx==0?'top-start':'top'} overlay={
                                                    <Tooltip className="dashboard_tooltip"
                                                    id={"registerTip2" + badges._id +badges.start_date}>
                                                      <span>
                                                        {badges?.module_title}
                                                      </span>
                                                      <br />
                                                      <span>
                                                        You Won The Badge
                                                      </span>
                                                      <span></span>
                                                    </Tooltip>} offset={[0,20]}>
                                                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip2" + badges._id +badges.start_date)} className="isb_badge"  id={"registerTip2" + badges._id +badges.start_date} onMouseLeave={() => HideDynamicBadge("registerTip2" + badges._id +badges.start_date)} >
                                                      <div className="isb_badge_text">
                                                        <p className="text-center mb-2 white_color">{badges?.module_title?.length >= 43 ?  badges?.module_title.slice(0, 43)+ '...' : badges?.module_title }</p>
                                                        {/* <p className="text-center white_color">{item?.course_title?.length >= 44 ? item?.course_title.slice(0, 44) + '...' : item?.course_title }</p> */}
                                                      </div>
                                                    </div>
                                                  </OverlayTrigger> :  
                                                  <div  onMouseEnter={() => ShowDynamicBadge("registerTip2" + badges._id +badges.start_date)} className="isb_badge"  id={"registerTip2" + badges._id +badges.start_date}>
                                                    <div className="isb_badge_text">
                                                      <p className="text-center mb-2 white_color">{badges?.module_title}</p>
                                                      {/* <p className="text-center white_color">{item?.course_title}</p> */}
                                                    </div>
                                                  </div>
                                                  }
                                                </Fragment>
                                                )
                                            })}
                                          </div> </> }
                                          <div className="text-left position-relative dashboard_moduleStatus">
                                            <div className="row align-items-end">
                                              <div className="col-12">
                                              {item.show_overview && <span className="d-block mt-0">
                                              You have completed{" "}
                                              {item.completed_modules_count}/
                                              {item.modules_count} Modules!
                                            </span>}

                                            <p style={{ color: "#057092" }} className="mt-1">
                                            {date_formats.convert_current_date_utc_format() < item.cohort_end_date ? (
                                              
                                              item.show_overview ==false ? <div>
                                                <span className="text-black">Welcome to the {selectedPrograme.learning_track_title} programme!</span>
                                                <br/>
                                                <br/>
                                                <span className="">Start Date: <b>{date_formats.human_date_format(item.cohort_start_date)}</b> </span>
                                                <br/>
                                                <span className="">End Date: <b>{date_formats.human_date_format(item.cohort_end_date)}</b> </span>
                                              </div>:
                                              <span>
                                              This course will end on {" "}<b>{date_formats.human_date_format(item.cohort_end_date)}</b>. <br/> 
                                                {item.show_overview && <>Your certificate will be issued upon achieving {Math.round(item.certificate_pass_percentage)?Math.round(item.certificate_pass_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% </>}
                                              </span>
                                            ) : (
                                              date_formats.convert_current_date_utc_format() >= item.cohort_end_date ? (
                                                item.module_release_date !== 0 ? (
                                                  <span>
                                                    The next module(s) will be released on{' '}
                                                    <b>{date_formats.human_date_format(item.module_release_date)}</b>
                                                  </span>
                                                ) : (
                                                  <span>
                                                    The course end date is extended until{' '}
                                                    <b>{date_formats.human_date_format(item.cohort_expiry_date)}</b>.<br/> Your certificate will be issued upon achieving {Math.round(item.certificate_pass_percentage)?Math.round(item.certificate_pass_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}%
                                                  </span>
                                                )
                                              ) : null
                                            )}
                                            </p>
                                              </div>
                                              <div className="">
                                              <div  key={item.index}>
                                              {item.cohort_start_date <=
                                              date_formats.convert_current_date_utc_format() &&
                                              item.cohort_expiry_date >
                                              date_formats.convert_current_date_utc_format() ? 
                                                (
                                                  item.is_paid == 1? 
                                                <>
                                                  {selectedPrograme?.application_status == 2 &&
                                                  item.completed_modules_count == 0? (
                                                    <button
                                                      onClick={() =>
                                                        redirectToModulePage(
                                                          item.course_id,
                                                          item.cohort_schedule_id
                                                        )
                                                      }
                                                      data-testid="courseStartorResume_mobile"
                                                      className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                    >
                                                      {item.course_started == 0 ?"Start":"Resume"} 
                                                    </button>
                                                  ) : (
                                                    <>
                                                      {item.completed_modules_count !=
                                                        item.modules_count &&
                                                      item.completed_modules_count >
                                                        0 ? (
                                                        <button
                                                          onClick={() =>
                                                            redirectToModulePage(
                                                              item.course_id,
                                                              item.cohort_schedule_id
                                                            )
                                                          }
                                                          data-testid="resumecourse_mobile"
                                                          className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                        >
                                                          Resume
                                                        </button>
                                                      ) : (
                                                        <>
                                                          {item.completed_modules_count ==
                                                          item.modules_count ? (
                                                            <button
                                                              onClick={() =>
                                                                redirectToModulePage(
                                                                  item.course_id,
                                                                  item.cohort_schedule_id
                                                                )
                                                              }
                                                              data-testid="review_course_mobile"
                                                              className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                            >
                                                              Review 
                                                            </button>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                                :'')
                                               : (
                                                (
                                                  <>{item.application_status == 2 &&
                                                   
                                                    item.is_paid == 1 &&item.cohort_start_date<date_formats.convert_current_date_utc_format()?
                                                    (item.show_overview && <button
                                                  onClick={() =>
                                                    history.push('/userprofile',{key:"Certificates-tab"})
                                                  }
                                                  data-testid="viewBadges&certificates_mobile"
                                                  className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                >
                                                View Badges & Certificates
                                                </button>):''}</>
                                                )
                                              )}
                                            </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <Fragment key={idx+"innerItems1"}></Fragment>
                                  );
                                })}
                              </div>
                              </div>
                              </>}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                {/* </Fragment>
              );
            })} */}
          </div>
          </>:
          <>
          <div className="container row dashboard_shadow bg-body text-left  mt-lg-5 web">
            <span data-testid="myProgrammes" className="my_program pt-4 pl-4">My Programmes</span>
            {programs.map((item1, index) => {
              return (
                <Fragment key={index}>
                  <div
                    key={index}
                    className="px-4 pb-4"
                    style={{ backgroundColor: "#FCFAFA" }}
                  >
                 {(item1 && item1.program_type == standalone_program_type) && item1.courses.length == 1 ?
                 <div className="d-flex justify-content-end mb-3">
                
                 {item1.application_status == 2 ? (
                   item1.courses[0].is_paid == 1 ? (
                    ''
                   ) : (
                     <button data-testid="make_Payment_web"
                       onClick={() => handlePayment(item1.program_id)}
                       className="btn dashboard_button mt-0"
                     >
                       Make Payment
                     </button>
                   )
                 ) : (
                   <p>
                     <b>Pending For Approval</b>
                   </p>
                 )}
               </div>  :
                 <div className="d-flex justify-content-between mb-3">
                      <h3 className="blue_text ml-sm-0">
                        <span data-testid="learningTrackTitle1" className="dashboard_subHead">{item1.learning_track_title}</span>
                      </h3>
                      {item1.application_status == 2 ? (
                        item1.courses[0].is_paid == 1 ? (
                          item1.program_start_date > date_formats.convert_current_date_utc_format() && (
                            <></>
                            // <button
                            //   onClick={showReminder}
                            //   className="btn dashboard_button mt-0"
                            // >
                            //   Get Reminder
                            // </button>
                          )
                        ) : (
                          <button data-testid="makePayment1"
                            onClick={() => handlePayment(item1.program_id)}
                            className="btn dashboard_button mt-0"
                          >
                            Make Payment
                          </button>
                        )
                      ) : (
                        <p data-testid="pendingforApproval1">
                          <b>Pending For Approval</b>
                        </p>
                      )}
                    </div>}
                    <div className="row ml-lg-1">
                      {(item1 && item1.program_type == standalone_program_type) && item1.courses.length == 1?
                      <div className="col-lg-4 pr-lg-5 " data-testid="Standalone_programe">
                        <div className="container dashboard_shadow standalone-course">
                        <p className="standalone-font"> {item1.learning_track_message?item1.learning_track_message:`This course is part of the ${selectedPrograme.learning_track_title} Learning Track`}</p>
                        <button className="learn_more" data-testid="learnmore_web" onClick={()=>goToStoreFront(item1.learning_track_url)}>Learn More<img src="images/learn_more.svg"></img></button>
                          </div>
                        </div>:
                        <div className="col-lg-4 pr-lg-5 coursenamePadding" data-testid="coursesList">
                        {item1.courses.map((course, ind) => {
                          return (
                            <Fragment key={ind}>
                              <div className="container mb-2">
                                <div data-testid={`singleCourse-${ind}`}
                                  className="row p-2 dashboard_shadow"
                                  style={{
                                    backgroundColor: course.background_color,
                                  }}
                                  onClick={() => setCourse(index, ind)}
                                >
                                  <div className="col-lg-1 col-sm-1 col-1 pl-1 pt-2">
                                    {course.cohort_start_date >
                                    date_formats.convert_current_date_utc_format() ? (
                                      <>
                                        {course.selected_item ? (
                                          <img className="dashboard-image-vertical-align" src="images/lock_selected.svg"></img>
                                        ) : (
                                          <img  className="dashboard-image-vertical-align" src="images/lock.svg"></img>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {course.modules_count ==
                                        course.completed_modules_count && course.course_completed_date? (
                                          course.selected_item ? (
                                            <img className="dashboard-image-vertical-align"  src="images/completed_selected.svg"></img>
                                          ) : (
                                            <img className="dashboard-image-vertical-align"  src="images/completed.svg"></img>
                                          )
                                        ) : course.completed_modules_count >=
                                          0 ? (
                                          course.selected_item ? (
                                            <img  className="dashboard-image-vertical-align" src="images/resume_selected.svg"></img>
                                          ) : (
                                            <img className="dashboard-image-vertical-align"  src="images/resume.svg"></img>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-11 col-sm-11 col-11 pr-1 cursor-pointer">
                                    <span data-testid="courseTitle"
                                      className="program_span"
                                      style={{ color: course.color }}
                                    >
                                      {course.course_title}
                                    </span>
                                    <br />
                                    <span className="program_span_status" style={{ color: course.color }}>
                                      {course.cohort_start_date <
                                      date_formats.convert_current_date_utc_format() ? (
                                        <>
                                          {course.modules_count ==
                                          course.completed_modules_count && course.course_completed_date 
                                            ? `Completed on ${date_formats.human_date_format(
                                                course.course_completed_date
                                              )} `
                                            : `${date_formats.convert_current_date_utc_format()>course.cohort_start_date?'Started':'Starts'} on ${date_formats.human_date_format(
                                                course.cohort_start_date
                                              )}`}
                                        </>
                                      ) : (
                                       
                                       `Starts on ${date_formats.human_date_format(
                                          course.cohort_start_date
                                        )}` 
                                      
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>}
                      <div className="col-lg-8 pl-lg-4">
                        {item1.courses.map((item, idx) => {
                          return item.selected_item ? (
                            <Fragment key={idx+"innerItems1"}>
                              <div className="container dashboard_shadow">
                                <div className="container">
                                  <img data-testid="courseImage"
                                    className="img-fluid"
                                    src={
                                      item.image === "" ||
                                      item.image === "null" ||
                                      item.image == null
                                        ? "images/course1.svg"
                                        : item.image
                                    }
                                    alt=""
                                  ></img>
                                </div>

                                <div className="p-4">
                                {item.show_overview && <><div className="d-flex justify-content-end">
                                    <>
                                      <ProgressBar
                                        // key={idx}
                                        completed={
                                          (100 / item.modules_count) *
                                          item.completed_modules_count
                                        }
                                      />
                                      <img data-testid="dashboard_coursecertificate"
                                        className="dashboard_coursecertificate course_certificate_img"
                                        src="./images/Course_Certificate_dashboard.png"
                                        alt=""
                                      />
                                    </>
                                  </div>
                                  <div
                                    className="row dashboard_badgesContainer" data-testid="module_badges">
                                    {item.modules.map((badges,idnx) => {
                                      return badges.is_module_completed ==
                                        false && badges.module_badge == 0 ? (
                                        <Fragment key={idnx+"innnerLoop2"+idx}>
                                          {dynamic_badge["registerTip0" + badges._id +badges.start_date] ? <>
                                          <div className="d-none" data-testid="finishbeforebadgeTooltip">
                                              <span>
                                                {badges?.module_title}
                                              </span>
                                              <br />
                                              <span>
                                              Finish before {date_formats.human_date_format(badges.end_date)}
                                              </span>
                                          </div>
                                           <OverlayTrigger placement="top" overlay={
                                            <Tooltip className="dashboard_tooltip"
                                            id={"registerTip0" + badges._id +badges.start_date}>
                                              <span>
                                              {badges?.module_title}
                                              </span>
                                              <br />
                                              <span>
                                                Finish before {date_formats.human_date_format(badges.end_date)}
                                              </span>
                                              <span></span>
                                            </Tooltip>} offset={[0,20]}>
                                            <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + badges._id +badges.start_date)} className="transparent_isb_badge"  id={"registerTip0" + badges._id +badges.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + badges._id +badges.start_date)} >
                                              <div className="isb_badge_text">
                                                <p className="text-center mb-2 white_color">{badges?.module_title?.length >=43 ?  badges?.module_title.slice(0, 43)+ '...' : badges?.module_title }</p>
                                                {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                              </div>
                                            </div>
                                          </OverlayTrigger> </> :  
                                          <div onMouseEnter={() => ShowDynamicBadge("registerTip0" + badges._id +badges.start_date)} className="transparent_isb_badge"  id={"registerTip0" + badges._id +badges.start_date}>
                                            <div className="isb_badge_text">
                                              <p className="text-center mb-2 white_color">{badges?.module_title}</p>
                                              {/* <p className="text-center white_color">{item.course_title}</p> */}
                                            </div>
                                          </div>
                                          }
                                         </Fragment>
                                        ) : badges.is_module_completed == true &&
                                        badges.module_badge == 0 ? (
                                          < Fragment key={idnx+"innnerLoop2"+idx}>
                                          {dynamic_badge["registerTip1" + badges._id +badges.start_date] ?<>
                                          <div className="d-none" data-testid="missedbadgeTooltip">
                                              <span>
                                                {badges?.module_title}
                                              </span>
                                              <br />
                                              <span>
                                                 You Missed This Badge
                                              </span>
                                          </div>
                                          
                                          <OverlayTrigger placement="top" overlay={
                                            <Tooltip className="dashboard_tooltip"
                                            id={"registerTip1" + badges._id +badges.start_date}>
                                              <span>
                                                {badges?.module_title}
                                              </span>
                                              <br />
                                              <span>
                                                You Missed This Badge
                                              </span>
                                              <span></span>
                                            </Tooltip>} offset={[0,20]}>
                                            <div  onMouseEnter={() => ShowDynamicBadge("registerTip1" + badges._id +badges.start_date)} className="transparent_isb_badge"  id={"registerTip1" + badges._id +badges.start_date} onMouseLeave={() => HideDynamicBadge("registerTip1" + badges._id +badges.start_date)} >
                                              <div className="isb_badge_text">
                                                <p className="text-center mb-2 white_color">{badges?.module_title?.length >= 43 ?  badges?.module_title.slice(0, 43)+ '...' : badges?.module_title }</p>
                                                {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                              </div>
                                            </div>
                                          </OverlayTrigger></> :  
                                          <div  onMouseEnter={() => ShowDynamicBadge("registerTip1" + badges._id +badges.start_date)} className="transparent_isb_badge"  id={"registerTip1" + badges._id +badges.start_date}>
                                            <div className="isb_badge_text">
                                              <p className="text-center mb-2 white_color">{badges?.module_title}</p>
                                              {/* <p className="text-center white_color">{item.course_title}</p> */}
                                            </div>
                                          </div>
                                          }
                                         </Fragment>
                                        ) : (
                                          <Fragment key={idnx+"innnerLoop2"+idx}>
                                          {dynamic_badge["registerTip2" + badges._id +badges.start_date] ?<>
                                          <div className="d-none" data-testid="wonbadgeTooltip">
                                              <span>
                                                {badges?.module_title}
                                              </span>
                                              <br />
                                              <span>
                                                You Won The Badge
                                              </span>
                                          </div>
                                           <OverlayTrigger placement="top" overlay={
                                            <Tooltip className="dashboard_tooltip"
                                            id={"registerTip2" + badges._id +badges.start_date}>
                                              <span>
                                                {badges?.module_title}
                                              </span>
                                              <br />
                                              <span>
                                                You Won The Badge
                                              </span>
                                              <span></span>
                                            </Tooltip>} offset={[0,20]}>
                                            <div  onMouseEnter={() => ShowDynamicBadge("registerTip2" + badges._id +badges.start_date)} className="isb_badge"  id={"registerTip2" + badges._id +badges.start_date} onMouseLeave={() => HideDynamicBadge("registerTip2" + badges._id +badges.start_date)} >
                                              <div className="isb_badge_text">
                                                <p className="text-center mb-2 white_color">{badges?.module_title?.length >= 43 ?  badges?.module_title.slice(0, 43)+ '...' : badges?.module_title }</p>
                                                {/* <p className="text-center white_color">{item?.course_title?.length >= 44 ? item?.course_title.slice(0, 44) + '...' : item?.course_title }</p> */}
                                              </div>
                                            </div>
                                          </OverlayTrigger></> :  
                                          <div  onMouseEnter={() => ShowDynamicBadge("registerTip2" + badges._id +badges.start_date)} className="isb_badge"  id={"registerTip2" + badges._id +badges.start_date}>
                                            <div className="isb_badge_text">
                                              <p className="text-center mb-2 white_color">{badges?.module_title}</p>
                                              {/* <p className="text-center white_color">{item?.course_title}</p> */}
                                            </div>
                                          </div>
                                          }
                                         </Fragment>
                                        )
                                    })}
                                  </div></>  }
                                  <div className="text-left position-relative dashboard_moduleStatus">
                                    <div className="row">
                                      <div className="col-7">
                                      {item.show_overview && <span data-testid="module_completedInfo">
                                      You have completed{" "}
                                      {item.completed_modules_count}/
                                      {item.modules_count} Modules!
                                    </span>}

                                    <p style={{ color: "#057092" }} className="mt-1" data-testid="courseandmoduleExtentedInfo">
                                    {date_formats.convert_current_date_utc_format() < item.cohort_end_date ? (
                                     
                                     item.show_overview ==false ?  <div>
                                        <span className="text-black mb-1">Welcome to the {item1.learning_track_title} programme!</span>
                                         
                                        <br/>
                                        <br/>
                                                <span className="">Start Date: <b>{date_formats.human_date_format(item.cohort_start_date)}</b> </span>
                                        <br/>
                                                <span className="">End Date: <b>{date_formats.human_date_format(item.cohort_end_date)}</b> </span>
                                      </div> : 
                                      <span>
                                      This course will end on {" "}<b>{date_formats.human_date_format(item.cohort_end_date)}</b>. 
                                        <br/> 
                                        {item.show_overview && <>Your certificate will be issued upon achieving {Math.round(item.certificate_pass_percentage)?Math.round(item.certificate_pass_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}%</>}
                                      </span>
                                    ) : (
                                      date_formats.convert_current_date_utc_format() >= item.cohort_end_date ? (
                                        item.module_release_date !== 0 ? (
                                          <span>
                                            The next module(s) will be released on{' '}
                                            <b>{date_formats.human_date_format(item.module_release_date)}</b>
                                          </span>
                                        ) : (
                                          <span>
                                            The course end date is extended until{' '}
                                            <b>{date_formats.human_date_format(item.cohort_expiry_date)}</b>.<br/>
                                            {item.show_overview &&<> Your certificate will be issued upon achieving {Math.round(item.certificate_pass_percentage)?Math.round(item.certificate_pass_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% </>}
                                          </span>
                                        )
                                      ) : null
                                    )}
                                    </p>
                                      </div>
                                      <div className="col">
                                      <div  key={item.index}>
                                      {item.cohort_start_date <=
                                      date_formats.convert_current_date_utc_format() &&
                                      item.cohort_expiry_date >
                                      date_formats.convert_current_date_utc_format() ? (
                                        
                                        <>
                                          {item.is_paid == 1?
                                            item1.application_status == 2 &&
                                            item.completed_modules_count == 0  ? (
                                            <button
                                              onClick={() =>
                                                redirectToModulePage(
                                                  item.course_id,
                                                  item.cohort_schedule_id
                                                )
                                              }
                                              data-testid="courseStartorResume"
                                              className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                            >
                                              {item.course_started == 0 ?"Start":"Resume Course"} 
                                            </button>
                                           ) : (
                                            <>
                                              {item.completed_modules_count !=
                                                item.modules_count &&
                                              item.completed_modules_count >
                                                0 &&
                                                item.cohort_expiry_date >
                                                date_formats.convert_current_date_utc_format() ? (
                                                <button
                                                  onClick={() =>
                                                    redirectToModulePage(
                                                      item.course_id,
                                                      item.cohort_schedule_id
                                                    )
                                                  }
                                                  data-testid="resumeCourse"
                                                  className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                >
                                                  Resume Course
                                                </button>
                                              ) : (
                                                <>
                                                  {item.completed_modules_count ==
                                                  item.modules_count  &&
                                                  item.cohort_expiry_date >
                                                  date_formats.convert_current_date_utc_format()  ? (
                                                    <button
                                                      onClick={() =>
                                                        redirectToModulePage(
                                                          item.course_id,
                                                          item.cohort_schedule_id
                                                        )
                                                      }
                                                      data-testid="reviewCourse"
                                                      className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                                    >
                                                      Review Course
                                                    </button>
                                                  ) : (
                                                    ''
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ):''}
                                        </>
                                      ) : (
                                        <>
                                         {item1.application_status == 2 &&  item.module_release_date == 0 &&
                                         
                                          item.is_paid == 1&& (item.cohort_start_date<date_formats.convert_current_date_utc_format()) ?
                                          (item.show_overview &&<button
                                        onClick={() =>
                                          history.push('/userprofile',{key:"Certificates-tab"})
                                        }
                                        data-testid="view&certificates"
                                        className="btn dashboard_button float-end dashboard_Btn_position top-0 end-0 pb-2"
                                      >
                                       View Badges & Certificates
                                      </button>):''}</>
                                      )}
                                    </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment key={idx+"innerItems1"}></Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
          </>}
       {show_loader ? <div className="blue_text py-3 container text-center align-items-center d-flex" > 
       <img src="/images/loading_dots.gif" style={{width:'5%'}} alt="Loader" ></img></div> :(show_more &&
        <div className="blue_text  text-center show_more_btn_styl fs-5 cursor-pointer" disabled={show_more_disable} onClick={showMore}>
      <button className="w-auto btn  dashboard_button" >Show More</button></div>)}
         
         
        </div>
        </>
      )}
      {verizon_student == 1 && (
        <Modal show={showModal} centered className="userprofile_modal" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}>

          <Modal.Body className='p-3'>
            <div className="text-center">
              <h5 className="blue_text pb-3">Name on Certificate</h5>
              <label>What name would you like to be printed on the certificate?</label>
              <input

                id="verizon_student"
                data-testid="verizon_student"
                name="verizon_student"
                autoFocus
                placeholder=" "
                role="presentation"
                autocomplete="off"
                type="text"
                value={user_name}
                onChange={handleChange}
                className="form-control boder-radius-0"
              />
            </div>
            <div className="text-center pt-2 redo_btn_container">
              <button data-testid="proceed_btn" className="btn my_btn_cls boder-radius-0 submit_button m-0 px-4 fw-bold" onClick={handleShowModal}>Proceed</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default withMaintenanceCheck(withScreenSizeCheck(DashBoard), user_maintenance_type);